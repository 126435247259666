import React from 'react'
import Icon from 'components/elements/Icon'

import { PublicUser } from 'services/apiTypes/user.types'

import Editor from 'components/formElements/richText/Editor'
import FollowButton from 'components/user/FollowButton'
import AvatarCircle from 'components/user/AvatarCircle'
import Link from 'next/link'

import { timeSince } from 'utils/time.utils'

import { emptyTextArea } from 'types/editor'

import styles from './userInfoAndBioCard.module.scss'
import { encodeUsernameRoute } from 'utils/encoding'

type Props = { user: PublicUser; className?: string }

const UserInfoAndBioCard = ({ user, className = '' }: Props) => (
  <div className={`${styles.container} ${className}`}>
    <div className={styles.info}>
      <div className={styles.user}>
        <AvatarCircle className={styles.avatar} src={user.profile.avatar} username={user.username} />
        <Link href={`/u/${encodeUsernameRoute(user.username)}`}>
          <Icon name="user" />
          {user.username}
        </Link>
        <FollowButton className={styles.followButton} followId={user.id} followed={user.following} />
      </div>
      <div className={styles.hr} />
      <div className={styles.meta}>
        <div>
          <b>Joined:</b> {timeSince(user.date_joined)} ago
        </div>
        <div>
          <b>Decks:</b> {user.deckCount}
        </div>
        <div>
          <b>Comments:</b> {user.commentCount}
        </div>
        <div>
          <b>Followers:</b> {user.followerCount}
        </div>
        <div>
          <b>Loyalty:</b> {user.points}
        </div>
      </div>
      <div className={`${styles.hr} ${styles.noShowOnDesktop}`} />
    </div>
    <div className={styles.bio}>
      {user.profile.bio === emptyTextArea ? (
        <div className={styles.noBio}>{user.username} has no bio</div>
      ) : (
        <Editor readOnly value={user.profile.bio} />
      )}
    </div>
  </div>
)

export default UserInfoAndBioCard
