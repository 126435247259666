import ReactGA from 'react-ga4'

import { DeepPartial } from 'types/deepPartial'

import { RequestService } from './request.service'

import {
  User,
  PublicUser,
  UserListResponseType,
  BasicUserListResponseType,
  FollowersResponseType,
  SmallDeckListResponse,
  LoginResponse,
} from './apiTypes/user.types'
import { fixDangjosStupidFuckingInternalProtocolIssue } from './card.service'

import { emptyTextArea } from 'types/editor'
import CookieService from './cookie.service'

// TODO - might wanna come up with a different placement for this
// Sidenote, why the heck isn't this in built into TS?

const isEmail: (email: string) => boolean = email => {
  // prettier-ignore
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email).toLowerCase())
}

export class UserService extends RequestService {
  public async update(user: DeepPartial<User>) {
    const id = this.cookies.get('tbId')
    const res = await super.patch<User>(`/api/users/${id}/private/`, user)

    CookieService.set('settings', res.accountSettings, 'month')

    return res
  }

  public uploadAvatar(file: File) {
    const id = this.cookies.get('tbId')

    return super.upload<string>(`/api/users/uploadAvatar/${id}/`, file)
  }

  public details(userId: string | number) {
    return super
      .get<PublicUser>(`/api/users/${userId}/`)
      .then(user => ({ ...user, profile: { ...user.profile, bio: user.profile.bio || emptyTextArea } }))
  }

  public detailsByUsername(username: string) {
    return super
      .get<PublicUser>(`/api/users/username/${username}/`)
      .then(user => ({ ...user, profile: { ...user.profile, bio: user.profile.bio || emptyTextArea } }))
  }

  public getMyUser() {
    const id = this.cookies.get('tbId')

    return super.get<User>(`/api/users/${id}/private/`)
  }

  public list(query: string): Promise<UserListResponseType> {
    return super.get(`/api/users/${query}`)
  }

  public basicList(username: string): Promise<BasicUserListResponseType> {
    return this.list(`?username=${username}&basicResults=True&orderBy=-related`)
  }

  public following(userId: string | number, next = ''): Promise<FollowersResponseType> {
    return super.get(next || `/api/users/${userId}/following/`).then(res => ({
      ...res,
      next: fixDangjosStupidFuckingInternalProtocolIssue(res.next),
      previous: fixDangjosStupidFuckingInternalProtocolIssue(res.previous),
    }))
  }

  public followers(userId: string | number, next = ''): Promise<FollowersResponseType> {
    return super.get(next || `/api/users/${userId}/followers/`).then(res => ({
      ...res,
      next: fixDangjosStupidFuckingInternalProtocolIssue(res.next),
      previous: fixDangjosStupidFuckingInternalProtocolIssue(res.previous),
    }))
  }

  public async connectPatreon(patreonCode: string) {
    const user = await super.post<User>(`/api/users/patreonConfirmation/`, { code: patreonCode })

    CookieService.set('tbTier', user.patreonAccount?.pledge?.level || null, 'month')

    return user
  }

  public disconnectPatreon() {
    return super.post<User>(`/api/users/disconnectPatreon/`, {})
  }

  public login(usernameOrEmail: string, password: string, rememberMe?: boolean): Promise<LoginResponse> {
    const requestObject: Record<string, any> = { password }

    if (isEmail(usernameOrEmail)) requestObject.email = usernameOrEmail
    else requestObject.username = usernameOrEmail

    return super.post<LoginResponse>('/api/rest-auth/login/', requestObject, undefined, true).then(data => {
      ReactGA.event({ category: 'User', action: 'User logged in V2' })

      const cookieExpirationAge = rememberMe ? 'month' : undefined

      CookieService.set('tbRefresh', data['refresh_token'], cookieExpirationAge)
      CookieService.set('tbUser', data.user.username, cookieExpirationAge)
      CookieService.set('tbJwt', data.token, cookieExpirationAge)
      CookieService.set('tbId', data.user.id, cookieExpirationAge)
      CookieService.set('tbDecks', data.user.decks, cookieExpirationAge)
      CookieService.set('tbR', data.user.profile.roles, cookieExpirationAge)
      CookieService.set('tbTier', data.user.patreonAccount?.pledge?.level || null, cookieExpirationAge)
      CookieService.set('tbRootFolder', data.user.rootFolder, cookieExpirationAge)
      CookieService.set('settings', data.user.accountSettings, cookieExpirationAge)
      CookieService.set('theme', data.user.accountSettings.siteTheme, cookieExpirationAge)

      return data
    })
  }

  public follow(personToFollowUserId: number | string, unfollow?: boolean) {
    const body = { followId: personToFollowUserId, unfollow }

    return super.post('/api/users/follow/', body)
  }

  public removeRole(userId: number | string, roleId: number | string) {
    // Yes, I know sending a delete as a body is non standard, it's a management endpoint
    return super.delete(`/api/users/roles/manage/`, { body: JSON.stringify({ roleId, userId }) }, true)
  }

  public addRole(userId: number | string, roleId: number | string) {
    return super.post(`/api/users/roles/manage/`, { roleId, userId })
  }

  public lock(userId: number | string) {
    return super.post(`/api/users/lock/`, { userId })
  }
}

const userService = new UserService()

export default userService
